import { createAction, props } from "@ngrx/store";
import { Establishment } from "src/app/services/api";

export const create = createAction("[Establishment] Create", props<{ establishment: Establishment }>());
export const createSuccess = createAction("[Establishment] Create Success", props<{ establishment: Establishment }>());
export const createFailure = createAction("[Establishment] Create Failure");

export const loadAll = createAction("[Establishment] Load All");
export const loadAllSuccess = createAction("[Establishment] Load All Success", props<{ establishments: Establishment[] }>());
export const loadAllFailure = createAction("[Establishment] Load All Failure");

export const loadOne = createAction("[Establishment] Load One", props<{ id: number }>());
export const loadOneSuccess = createAction("[Establishment] Load One Success", props<{ establishment: Establishment }>());
export const loadOneFailure = createAction("[Establishment] Load One Failure");

export const update = createAction("[Establishment] Update", props<{ id: number; establishment: Establishment }>());
export const updateSuccess = createAction("[Establishment] Update Success", props<{ establishment: Establishment }>());
export const updateFailure = createAction("[Establishment] Update Failure");

export const remove = createAction("[Establishment] Remove", props<{ id: number }>());
export const removeSuccess = createAction("[Establishment] Remove Success", props<{ id: number }>());
export const removeFailure = createAction("[Establishment] Remove Failure");

export const reset = createAction("[Establishment] Reset");
