import { Category } from "src/app/services/api";

export interface Categories {
  [id: number]: Category;
}

export interface CategoryState {
  categories: Categories;
  selected: number;
  loading: boolean;
}

export const initialState: CategoryState = {
  categories: {},
  selected: null,
  loading: false,
};
