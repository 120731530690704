import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as EstablishmentActions from "./actions";

export const establishmentReducer = createReducer(
  initialState,

  on(EstablishmentActions.reset, () => initialState),

  on(
    EstablishmentActions.create,
    EstablishmentActions.loadAll,
    EstablishmentActions.loadOne,
    EstablishmentActions.update,
    EstablishmentActions.remove,
    state => ({ ...state, loading: true }),
  ),

  on(
    EstablishmentActions.loadAllFailure,
    EstablishmentActions.loadOneFailure,
    EstablishmentActions.createFailure,
    EstablishmentActions.updateFailure,
    EstablishmentActions.removeFailure,
    state => ({ ...state, loading: false }),
  ),

  on(EstablishmentActions.loadAllSuccess, (state, { establishments }) => {
    const entities = {};
    establishments.forEach(establishment => (entities[establishment.id] = establishment));
    return {
      ...state,
      establishments: entities,
      loading: false,
    };
  }),

  on(
    EstablishmentActions.createSuccess,
    EstablishmentActions.loadOneSuccess,
    EstablishmentActions.updateSuccess,
    (state, { establishment }) => ({
      ...state,
      establishments: {
        ...state.establishments,
        [establishment.id]: establishment,
      },
      loading: false,
    }),
  ),
);
