import { User } from "src/app/services/api";

export interface UserState {
  user: User;
  accessToken: string;
  refreshToken: string;
  loading: boolean;
}

export const initialState: UserState = {
  user: null,
  accessToken: null,
  refreshToken: null,
  loading: false,
};
