/* eslint-disable no-shadow, @typescript-eslint/naming-convention */

export enum ROUTES {
  ADD = "add",
  CATEGORIES = "categories",
  CONFIRM_EMAIL = "confirm-email",
  CONTACT = "contact",
  DASHBOARD = "",
  EDIT = "edit",
  ESTABLISHMENTS = "establishments",
  LOGIN = "login",
  MENUS = "menus",
  PANEL = "",
  PRODUCTS = "products",
  REGISTER = "register",
  RESET_PASSWORD = "reset-password",
  SETTINGS = "settings",
  SUMMARY = "summary",
}

export enum QUERY_PARAMS {
  EMAIL = "email",
  TOKEN = "token",
}
