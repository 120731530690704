/* eslint-disable @typescript-eslint/naming-convention */
import { Establishment } from "../services/api";

export const DEFAULT_LANGUAGE: Establishment.EnabledLanguagesEnum = Establishment.EnabledLanguagesEnum.Spanish;

export const DISABLED_LANGUAGES: Establishment.EnabledLanguagesEnum[] = [
  Establishment.EnabledLanguagesEnum.French,
  Establishment.EnabledLanguagesEnum.Portuguese,
  Establishment.EnabledLanguagesEnum.German,
  Establishment.EnabledLanguagesEnum.Italian,
];

export const MAP_LANGUAGE_ISO_TO_ENUM: { [key: string]: Establishment.EnabledLanguagesEnum } = {
  es: Establishment.EnabledLanguagesEnum.Spanish,
  en: Establishment.EnabledLanguagesEnum.English,
  ca: Establishment.EnabledLanguagesEnum.Catalan,
  de: Establishment.EnabledLanguagesEnum.German,
  fr: Establishment.EnabledLanguagesEnum.French,
  it: Establishment.EnabledLanguagesEnum.Italian,
  pt: Establishment.EnabledLanguagesEnum.Portuguese,
};

export const MAP_LANGUAGE_ENUM_TO_ISO: { [key in Establishment.EnabledLanguagesEnum]: string } = {
  SPANISH: "es",
  ENGLISH: "en",
  CATALAN: "ca",
  GERMAN: "de",
  FRENCH: "fr",
  ITALIAN: "it",
  PORTUGUESE: "pt",
};
