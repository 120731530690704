import { createAction, props } from "@ngrx/store";
import { Category, CreateTranslationDto, Translation } from "src/app/services/api";

export const create = createAction("[Category] Create", props<{ category: Category }>());

export const createSuccess = createAction("[Category] Create Success", props<{ category: Category }>());

export const createFailure = createAction("[Category] Create Failure");

export const loadAll = createAction("[Category] Load All");

export const loadAllSuccess = createAction("[Category] Load All Success", props<{ categories: Category[] }>());

export const loadAllFailure = createAction("[Category] Load All Failure");

export const loadOne = createAction("[Category] Load One", props<{ id: number }>());

export const loadOneSuccess = createAction("[Category] Load One Success", props<{ category: Category }>());

export const loadOneFailure = createAction("[Category] Load One Failure");

export const update = createAction("[Category] Update", props<{ id: number; category: Category }>());

export const updateSuccess = createAction("[Category] Update Success", props<{ category: Category }>());

export const updateFailure = createAction("[Category] Update Failure");

export const remove = createAction("[Category] Remove", props<{ id: number }>());

export const removeSuccess = createAction("[Category] Remove Success", props<{ id: number }>());

export const removeFailure = createAction("[Category] Remove Failure");

export const select = createAction("[Category] Select", props<{ id: number }>());

export const deselect = createAction("[Category] Deselect");

export const addTranslation = createAction("[Category] Add Translation", props<{ id: number; translation: Translation }>());

export const createTranslation = createAction("[Category] Save Translation", props<{ id: number; translation: CreateTranslationDto }>());

export const createTranslationSuccess = createAction(
  "[Category] Save Translation Success",
  props<{ id: number; translation: Translation }>(),
);

export const createTranslationFailure = createAction("[Category] Save Translation Failure");

export const updateTranslation = createAction(
  "[Category] Update Translation",
  props<{ id: number; categoryId: number; translation: CreateTranslationDto }>(),
);

export const updateTranslationSuccess = createAction(
  "[Category] Update Translation Success",
  props<{ id: number; categoryId: number; translation: Translation }>(),
);

export const updateTranslationFailure = createAction("[Category] Update Translation Failure");

export const removeNonSavedTranslation = createAction(
  "[Category] Remove Non Saved Translation",
  props<{ categoryId: number; translation: Translation }>(),
);

export const removeTranslation = createAction("[Category] Remove Translation", props<{ categoryId: number; translation: Translation }>());

export const removeTranslationSuccess = createAction(
  "[Category] Remove Translation Success",
  props<{ categoryId: number; translation: Translation }>(),
);

export const removeTranslationFailure = createAction("[Category] Remove Translation Failure");

export const reset = createAction("[Category] Reset");
