import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap } from "rxjs/operators";
import { TranslationsService } from "src/app/services/api";
import * as TranslationActions from "./actions";

@Injectable()
export class TranslationEffects {
  public loadByMenu$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(TranslationActions.loadByMenu),
      mergeMap(({ menuId }) =>
        this.translationsService.translationControllerGetByMenu(menuId).pipe(
          map(translations => TranslationActions.loadByMenuSuccess({ translations })),
          catchError(() => of(TranslationActions.loadByMenuFailure())),
        ),
      ),
    ),
  );

  public remove$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(TranslationActions.remove),
      mergeMap(({ id }) =>
        this.translationsService.translationControllerRemove(id).pipe(
          map(() => TranslationActions.removeSuccess({ id })),
          catchError(() => of(TranslationActions.removeFailure())),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private translationsService: TranslationsService,
  ) {}
}
