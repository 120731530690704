import { createAction, props } from "@ngrx/store";
import { ChangePasswordDto, GoogleTokenDto, RefreshTokenDto, ResetPasswordDto, SetNewPasswordDto, SignInDto, SignUpDto, User } from "src/app/services/api";

interface Tokens {
  accessToken: string;
  refreshToken: string;
}

export const register = createAction("[User] Register", props<SignUpDto>());

export const registerSuccess = createAction("[User] Register Success", props<Tokens>());

export const registerFailure = createAction("[User] Register Failure");

export const login = createAction("[User] Login", props<SignInDto>());
export const loginWithGoogle = createAction("[User] Login With Google", props<GoogleTokenDto>());

export const loginSuccess = createAction("[User] Login Success", props<Tokens>());

export const loginFailure = createAction("[User] Login Failure");

export const logout = createAction("[User] Logout");

export const refreshToken = createAction("[User] Refresh Token", props<RefreshTokenDto>());

export const refreshTokenSuccess = createAction("[User] Refresh Token Success", props<Tokens>());

export const resetPassword = createAction("[User] Reset Password", props<ResetPasswordDto>());

export const resetPasswordSuccess = createAction("[User] Reset Password Success");

export const resetPasswordFailure = createAction("[User] Reset Password Failure");

export const setNewPassword = createAction("[User] Set New Password", props<SetNewPasswordDto & { authorization: string }>());

export const setNewPasswordSuccess = createAction("[User] Set New Password Success", props<Tokens>());

export const setNewPasswordFailure = createAction("[User] Set New Password Failure");

export const confirmEmail = createAction("[User] Confirm Email");

export const confirmEmailSuccess = createAction("[User] Confirm Email Success", props<Tokens>());

export const confirmEmailFailure = createAction("[User] Confirm Email Failure");

export const load = createAction("[User] Load", props<{ navigate?: boolean }>());

export const loadSuccess = createAction("[User] Load Success", props<{ user: User }>());

export const storeAccessToken = createAction("[User] Store Access Token", props<{ accessToken: string }>());

export const updateUser = createAction("[User] Update User", props<{ user: User }>());

export const updateUserSuccess = createAction("[User] Update User Success", props<{ user: User }>());

export const updateUserFailure = createAction("[User] Update User Failure");

export const reset = createAction("[User] Reset");

export const changePassword = createAction("[User] Change Password", props<{ userId: number; payload: ChangePasswordDto }>());

export const changePasswordSuccess = createAction("[User] Change Password Success", props<{ user: User }>());

export const changePasswordFailure = createAction("[User] Change Password Failure");

export const deleteAccount = createAction("[User] Delete Account", props<{ user: User }>());

export const deleteAccountSuccess = createAction("[User] Delete Account Success");

export const deleteAccountFailure = createAction("[User] Delete Account Failure");

export const changeUserSubscription = createAction("[User] Change User Subscription", props<{ subscription: User.SubscriptionEnum }>());

export const changeUserSubscriptionFailure = createAction("[User] Change User Subscription Failure");

export const changeUserSubscriptionConfirmed = createAction(
  "[User] Change User Subscription Confirmed",
  props<{ subscription: User.SubscriptionEnum }>(),
);

export const contact = createAction("[User] Contact", props<{ email: string; subject: string; message: string }>());
export const contactSuccess = createAction("[User] Contact Success");
export const contactFailure = createAction("[User] Contact Failure");
