import { ActionReducerMap } from "@ngrx/store";
import { MenuState } from "./menu/state";
import { UserState } from "./user/state";
import { menuReducer } from "./menu/reducer";
import { userReducer } from "./user/reducer";
import { establishmentReducer } from "./establishment/reducer";
import { EstablishmentState } from "./establishment/state";
import { CategoryState } from "./category/state";
import { categoryReducer } from "./category/reducer";
import { ProductState } from "./product/state";
import { productReducer } from "./product/reducer";
import { AppState } from "./app/state";
import { appReducer } from "./app/reducer";
import { UserEffects } from "./user/effects";
import { EstablishmentEffects } from "./establishment/effects";
import { CategoryEffects } from "./category/effects";
import { MenuEffects } from "./menu/effects";
import { ProductEffects } from "./product/effects";
import { AppEffects } from "./app/effects";
import { TranslationState } from "./translation/state";
import { translationReducer } from "./translation/reducer";
import { TranslationEffects } from "./translation/effects";
import { routerReducer, RouterReducerState } from "@ngrx/router-store";

export interface RootState {
  app: AppState;
  category: CategoryState;
  establishment: EstablishmentState;
  menu: MenuState;
  product: ProductState;
  router: RouterReducerState<any>;
  translation: TranslationState;
  user: UserState;
}

export const reducers: ActionReducerMap<RootState> = {
  app: appReducer,
  category: categoryReducer,
  establishment: establishmentReducer,
  menu: menuReducer,
  product: productReducer,
  router: routerReducer,
  translation: translationReducer,
  user: userReducer,
};

export const effects = [
  AppEffects,
  CategoryEffects,
  EstablishmentEffects,
  MenuEffects,
  ProductEffects,
  TranslationEffects,
  UserEffects,
];

export * as UserActions from "src/app/store/user/actions";

export * as FromApp from "src/app/store/app/selectors";
export * as FromRouter from "src/app/store/router/selectors";
export * as FromUser from "src/app/store/user/selectors";
