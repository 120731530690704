import { Injectable } from "@angular/core";
import { createEffect, ofType } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { RootState } from "..";
import * as EstablishmentActions from "./actions";
import * as FromUser from "src/app/store/user/selectors";
import { NavController } from "@ionic/angular";
import { ROUTES } from "src/app/constants/routes";
import { ToastService } from "src/app/services/toast/toast.service";
import { EstablishmentsService } from "src/app/services/api";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class EstablishmentEffects {
  public loadAll$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EstablishmentActions.loadAll),
      withLatestFrom(this.store.select(FromUser.selectUser)),
      switchMap(([_, user]) => this.establishmentService.establishmentControllerFindByUser(user.id).pipe(
        map(establishments => EstablishmentActions.loadAllSuccess({ establishments })),
        catchError(() => of(EstablishmentActions.loadAllFailure())),
      )),
    ),
  );

  public loadOne$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EstablishmentActions.loadOne),
      mergeMap(action =>
        this.establishmentService.establishmentControllerFindOne(action.id).pipe(
          map(establishment => EstablishmentActions.loadOneSuccess({ establishment })),
          catchError(() => of(EstablishmentActions.loadOneFailure())),
        ),
      ),
    ),
  );

  public create$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EstablishmentActions.create),
      mergeMap(action =>
        this.establishmentService.establishmentControllerCreate(action.establishment).pipe(
          map(establishment => EstablishmentActions.createSuccess({ establishment })),
          tap(resp => of(this.navController.navigateRoot([ROUTES.ESTABLISHMENTS, resp.establishment.id, ROUTES.SUMMARY]))),
          catchError(() => of(EstablishmentActions.createFailure())),
        ),
      ),
    ),
  );

  public update$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EstablishmentActions.update),
      mergeMap(action =>
        this.establishmentService.establishmentControllerUpdate(action.establishment, action.id).pipe(
          map(establishment => EstablishmentActions.updateSuccess({ establishment })),
          tap(() => of(this.toastService.presentToast(this.i18n.instant("Toast.successfullyUpdated"), "success"))),
          catchError(() => of(EstablishmentActions.updateFailure())),
        ),
      ),
    ),
  );

  public remove$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(EstablishmentActions.remove),
      mergeMap(action =>
        this.establishmentService.establishmentControllerRemove(action.id).pipe(
          map(() => EstablishmentActions.removeSuccess({ id: action.id })),
          tap(() => of(this.navController.navigateBack([ROUTES.ESTABLISHMENTS]))),
          catchError(() => of(EstablishmentActions.createFailure())),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private i18n: TranslateService,
    private establishmentService: EstablishmentsService,
    private navController: NavController,
    private toastService: ToastService,
  ) {}
}
