import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AngularFireAnalytics } from "@angular/fire/compat/analytics";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AlertController, NavController } from "@ionic/angular";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, of } from "rxjs";
import { catchError, concatMap, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import { ROUTES } from "src/app/constants/routes";
import { AuthService, Establishment, PaymentsService, User, UsersService } from "src/app/services/api";
import { parseLanguageToStripeLocale } from "src/app/services/i18n/translate-loader";
import { LoadingService } from "src/app/services/loading/loading.service";
import { ToastService } from "src/app/services/toast/toast.service";
import { environment } from "src/environments/environment";
import { StripeScriptTag } from "stripe-angular";
import { RootState } from "..";
import * as UserActions from "./actions";
import * as FromUser from "./selectors";

@Injectable()
export class UserEffects {
  public register$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.register),
      mergeMap(({ email, name, password, subscription, language }) =>
        this.authService.authenticationControllerRegister({ language, email, name, password, subscription }).pipe(
          map(tokens => UserActions.registerSuccess(tokens)),
          tap(() => this.showRegisterSuccessAlert(email)),
          catchError(() => of(UserActions.registerFailure())),
        ),
      ),
    ),
  );

  public confirmEmail$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.confirmEmail),
      mergeMap(() =>
        this.authService.authenticationControllerConfirmEmail().pipe(
          concatMap(tokens => [UserActions.confirmEmailSuccess(tokens), UserActions.load({})]),
          tap(() => of(this.navController.navigateBack([ROUTES.LOGIN]))),
        ),
      ),
    ),
  );

  public login$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.login),
      mergeMap(({ password, username }) =>
        this.authService.authenticationControllerLogin({ password, username }).pipe(
          concatMap(tokens => [UserActions.loginSuccess(tokens), UserActions.load({ navigate: true })]),
          catchError(() => of(UserActions.loginFailure())),
        ),
      ),
    ),
  );

  public loginWithGoogle$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginWithGoogle),
      mergeMap(({ token }) =>
        this.authService.googleAuthenticationControllerAuthenticate({ token }).pipe(
          concatMap(tokens => [UserActions.loginSuccess(tokens), UserActions.load({ navigate: true })]),
          catchError(() => of(UserActions.loginFailure())),
        ),
      ),
    ),
  );

  public resetPassword$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.resetPassword),
      mergeMap(({ email }) =>
        this.authService.authenticationControllerResetPassword({ email }).pipe(
          map(() => UserActions.resetPasswordSuccess()),
          tap(() => {
            this.toast.presentToast(this.i18n.instant("Toast.willReceiveResetLink"), "dark");
            this.navController.navigateRoot([ROUTES.LOGIN], { queryParams: { email } });
          }),
          catchError(() => of(UserActions.resetPasswordFailure())),
        ),
      ),
    ),
  );

  public setNewPassword$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.setNewPassword),
      mergeMap(({ password, authorization }) =>
        this.authService.authenticationControllerSetNewPassword({ password }, authorization).pipe(
          concatMap(tokens => [UserActions.setNewPasswordSuccess(tokens), UserActions.load({})]),
          tap(() => of(this.navController.navigateBack([ROUTES.PANEL]))),
          catchError(() => of(UserActions.setNewPasswordFailure())),
        ),
      ),
    ),
  );

  public refresh$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.refreshToken),
      mergeMap(({ refreshToken }) =>
        this.authService
          .authenticationControllerRefresh({ refreshToken })
          .pipe(concatMap(tokens => [UserActions.refreshTokenSuccess(tokens), UserActions.load({})])),
      ),
    ),
  );

  public load$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.load),
      mergeMap(({ navigate }) =>
        this.userService.userControllerFindOne().pipe(
          map(user => UserActions.loadSuccess({ user })),
          tap(action => {
            this.auth.signInAnonymously();
            this.analytics.setUserId(action.user.id.toString());
            this.analytics.setUserProperties({ email: action.user.email, subscription: action.user.subscription });
            if (navigate) this.navController.navigateBack([ROUTES.PANEL]);
          }),
        ),
      ),
    ),
  );

  public updateUser: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateUser),
      mergeMap(action =>
        this.userService.userControllerUpdate(action.user, action.user.id).pipe(
          map(user => UserActions.updateUserSuccess({ user })),
          catchError(() => of(UserActions.updateUserFailure)),
        ),
      ),
    ),
  );

  public changePasword: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.changePassword),
      mergeMap(action =>
        this.userService.userControllerChangePassword({ ...action.payload }, action.userId).pipe(
          map(user => UserActions.changePasswordSuccess({ user })),
          tap(() => this.toast.presentToast(this.i18n.instant("Toast.passwordUpdated"), "dark")),
          catchError(() => of(UserActions.changePasswordFailure())),
        ),
      ),
    ),
  );

  public deleteAccount: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.deleteAccount),
      mergeMap(action =>
        this.userService.userControllerRemove(action.user.id).pipe(
          tap(() => of(this.navController.navigateBack([ROUTES.REGISTER]))),
          map(() => UserActions.deleteAccountSuccess()),
          catchError(() => of(UserActions.deleteAccountFailure())),
        ),
      ),
    ),
  );

  public changeUserSubscription$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.changeUserSubscription),
        withLatestFrom(this.store.select(FromUser.selectActualUserSubscription)),
        tap(([{ subscription }, currentSubscription]) => {
          if (subscription === currentSubscription) return null;
          this.showConfirmChangeSubscriptionAlert(subscription);
        }),
      ),
    { dispatch: false },
  );

  public changeUserSubscriptionConfirmed$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.changeUserSubscriptionConfirmed),
        mergeMap(({ subscription }) =>
          this.paymentService
            .paymentControllerCreateCheckoutSession({
              locale: parseLanguageToStripeLocale(this.i18n.currentLang as Establishment.EnabledLanguagesEnum),
              subscription,
            })
            .pipe(
              tap(({ sessionId, type }) => {
                if (type === "checkout") this.stripe.redirectToCheckout({ sessionId });
                else this.document.location.replace(sessionId);
              }),
              catchError(() => of(UserActions.changeUserSubscriptionFailure())),
            ),
        ),
      ),
    { dispatch: false },
  );

  public contact$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.contact),
      mergeMap(({ email, subject, message }) =>
        this.httpClient.post("https://hook.integromat.com/fwobaq4a8hcj3tweocomuw9iihbcc7nr", { email, subject, message }, { responseType: "text" }).pipe(
          map(() => UserActions.contactSuccess()),
          catchError(() => of(UserActions.contactFailure())),
        ),
      ),
    ),
  );

  public contactSuccess$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.contactSuccess),
      tap(() => this.toast.presentToast(this.i18n.instant("Contact.successfullySent"), "dark")),
    ), { dispatch: false },
  );

  private stripe: stripe.Stripe;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private actions$: Actions,
    private store: Store<RootState>,
    private httpClient: HttpClient,
    private authService: AuthService,
    private userService: UsersService,
    private paymentService: PaymentsService,
    private navController: NavController,
    private analytics: AngularFireAnalytics,
    private auth: AngularFireAuth,
    private alertController: AlertController,
    private loadingService: LoadingService,
    private toast: ToastService,
    private i18n: TranslateService,
    private stripeScriptTag: StripeScriptTag,
  ) {
    this.stripeScriptTag.setPublishableKey(environment.stripePublishableKey).then(stripe => {
      this.stripe = stripe;
    });
  }

  private async showRegisterSuccessAlert(email: string): Promise<void> {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: this.i18n.instant("Alert.registerSuccessHeader"),
      message: this.i18n.instant("Alert.registerSuccessMessage", { email }),
      mode: "ios",
      buttons: [
        {
          text: this.i18n.instant("Buttons.ok"),
          cssClass: ["alert-regular-button"],
          handler: async () => await this.navController.navigateRoot([ROUTES.LOGIN], { queryParams: { email } }),
        },
      ],
    });
    await alert.present();
  }

  private async showConfirmChangeSubscriptionAlert(subscription: User.SubscriptionEnum): Promise<void> {
    const alert = await this.alertController.create({
      backdropDismiss: false,
      header: this.i18n.instant("Alert.changeSubscriptionHeader", { subscription: this.i18n.instant(`Subscription.${subscription}`) }),
      message: this.i18n.instant("Alert.changeSubscriptionMessage", {
        subscription: this.i18n.instant(`Subscription.${subscription}`),
      }),
      mode: "ios",
      buttons: [
        {
          text: this.i18n.instant("Buttons.cancel"),
          role: "cancel",
          cssClass: ["alert-danger-button"],
        },
        {
          text: this.i18n.instant("Buttons.ok"),
          cssClass: ["alert-regular-button"],
          handler: async (): Promise<void> => {
            await this.loadingService.present();
            this.store.dispatch(UserActions.changeUserSubscriptionConfirmed({ subscription }));
          },
        },
      ],
    });

    await alert.present();
  }
}
