import { Injectable } from "@angular/core";
import { createEffect, ofType } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { RootState } from "..";
import * as ProductActions from "./actions";
import * as FromUser from "src/app/store/user/selectors";
import { NavController } from "@ionic/angular";
import { ROUTES } from "src/app/constants/routes";
import { ToastService } from "src/app/services/toast/toast.service";
import { PricesService, ProductsService, TranslationsService } from "src/app/services/api";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class ProductEffects {
  public loadAll$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.loadAll),
      withLatestFrom(this.store.select(FromUser.selectUser)),
      switchMap(([, user]) => this.productService.productControllerFindByUser(user.id).pipe(
        map(products => ProductActions.loadAllSuccess({ products })),
        catchError(() => of(ProductActions.loadAllFailure())),
      )),
    ),
  );

  public loadOne$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.loadOne),
      mergeMap(action =>
        this.productService.productControllerFindOne(action.id).pipe(
          map(product => ProductActions.loadOneSuccess({ product })),
          catchError(() => of(ProductActions.loadOneFailure())),
        ),
      ),
    ),
  );

  public loadByCategory$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.loadByCategory),
      mergeMap(action =>
        this.productService.productControllerFindByCategory(action.categoryId).pipe(
          map(products => ProductActions.loadByCategorySuccess({ products })),
          catchError(() => of(ProductActions.loadByCategoryFailure())),
        ),
      ),
    ),
  );

  public create$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.create),
      mergeMap(action =>
        this.productService.productControllerCreate(action.product).pipe(
          map(product => ProductActions.createSuccess({ product })),
          catchError(() => of(ProductActions.createFailure())),
        ),
      ),
    ),
  );

  public update$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.update),
      mergeMap(action =>
        this.productService.productControllerUpdate(action.product, action.id).pipe(
          map(product => ProductActions.updateSuccess({ product })),
          tap(() => of(this.toastService.presentToast(this.i18n.instant("Toast.successfullyUpdated"), "dark"))),
          catchError(() => of(ProductActions.updateFailure())),
        ),
      ),
    ),
  );

  public remove$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.remove),
      mergeMap(action =>
        this.productService.productControllerRemove(action.id).pipe(
          map(() => ProductActions.removeSuccess({ id: action.id })),
          tap(() => of(this.navController.navigateBack([ROUTES.PRODUCTS]))),
          catchError(() => of(ProductActions.createFailure())),
        ),
      ),
    ),
  );

  public createTranslation$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.createTranslation),
      mergeMap(action =>
        this.i18nService.translationControllerCreate(action.translation).pipe(
          map(translation => ProductActions.createTranslationSuccess({ productId: action.productId, translation })),
          catchError(() => of(ProductActions.createTranslationFailure())),
        ),
      ),
    ),
  );

  public updateTranslation$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.updateTranslation),
      mergeMap(action =>
        this.i18nService.translationControllerUpdate(action.translation, action.id).pipe(
          map(translation => ProductActions.updateTranslationSuccess({ id: action.id, productId: action.productId, translation })),
          catchError(() => of(ProductActions.updateTranslationFailure())),
        ),
      ),
    ),
  );

  public removeTranslation$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.removeTranslation),
      mergeMap(action =>
        this.i18nService.translationControllerRemove(action.translation.id).pipe(
          map(() => ProductActions.removeTranslationSuccess({ ...action })),
          catchError(() => of(ProductActions.removeTranslationFailure())),
        ),
      ),
    ),
  );

  public createPrice$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.createPrice),
      mergeMap(action =>
        this.priceService.priceControllerCreate(action.price).pipe(
          map(price => ProductActions.createPriceSuccess({ productId: action.productId, index: action.index, price })),
          catchError(() => of(ProductActions.createPriceFailure())),
        ),
      ),
    ),
  );

  public updatePrice$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.updatePrice),
      mergeMap(action =>
        this.priceService.priceControllerUpdate(action.price, action.id).pipe(
          map(price => ProductActions.updatePriceSuccess({ id: action.id, productId: action.productId, price })),
          catchError(() => of(ProductActions.updatePriceFailure())),
        ),
      ),
    ),
  );

  public removePrice$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.removePrice),
      mergeMap(action =>
        this.priceService.priceControllerRemove(action.id).pipe(
          map(() => ProductActions.removePriceSuccess({ id: action.id, productId: action.productId })),
          catchError(() => of(ProductActions.removePriceFailure())),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private i18n: TranslateService,
    private productService: ProductsService,
    private i18nService: TranslationsService,
    private priceService: PricesService,
    private navController: NavController,
    private toastService: ToastService,
  ) {}
}
