import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as ProductActions from "./actions";

export const productReducer = createReducer(
  initialState,

  on(ProductActions.reset, () => initialState),

  on(
    ProductActions.create,
    ProductActions.loadAll,
    ProductActions.loadOne,
    ProductActions.update,
    ProductActions.remove,
    ProductActions.createTranslation,
    ProductActions.updateTranslation,
    ProductActions.createPrice,
    ProductActions.updatePrice,
    ProductActions.removePrice,
    state => ({ ...state, loading: true }),
  ),
  on(ProductActions.loadByCategory, ProductActions.removeTranslation, state => ({ ...state, loading: true })),

  on(
    ProductActions.loadAllFailure,
    ProductActions.loadOneFailure,
    ProductActions.createFailure,
    ProductActions.updateFailure,
    ProductActions.removeFailure,
    ProductActions.createTranslationFailure,
    ProductActions.updateTranslationFailure,
    ProductActions.createPriceFailure,
    ProductActions.updatePriceFailure,
    ProductActions.removePriceFailure,
    state => ({ ...state, loading: false }),
  ),
  on(ProductActions.loadByCategoryFailure, ProductActions.removeTranslationFailure, state => ({ ...state, loading: false })),

  on(ProductActions.select, (state, { id }) => ({ ...state, selected: id })),

  on(ProductActions.deselect, state => ({ ...state, selected: null })),

  on(ProductActions.loadAllSuccess, ProductActions.loadByCategorySuccess, (state, { products }) => {
    const entities = {};
    products.forEach(product => (entities[product.id] = product));

    return {
      ...state,
      products: {
        ...state.products,
        ...entities,
      },
      loading: false,
    };
  }),

  on(ProductActions.createSuccess, ProductActions.loadOneSuccess, ProductActions.updateSuccess, (state, { product }) => {
    const oldOne = state.products[product.id];

    return {
      ...state,
      products: {
        ...state.products,
        [product.id]: { ...oldOne, ...product },
      },
      selected: product.id,
      loading: false,
    };
  }),

  on(ProductActions.removeSuccess, (state, { id }) => {
    const products = Object.values(state.products).filter(product => product.id !== id);
    const entities = {};
    products.forEach(product => (entities[product.id] = product));

    return {
      ...state,
      products: entities,
      loading: false,
    };
  }),

  on(ProductActions.addTranslation, (state, { id, translation }) => ({
    ...state,
    products: {
      ...state.products,
      [id]: {
        ...state.products[id],
        translations: [...state.products[id].translations, translation],
      },
    },
    loading: false,
  })),

  on(ProductActions.removeNonSavedTranslation, ProductActions.removeTranslationSuccess, (state, { productId, translation }) => ({
    ...state,
    products: {
      ...state.products,
      [productId]: {
        ...state.products[productId],
        translations: [...state.products[productId].translations].filter(t => t.language !== translation.language),
      },
    },
  })),

  on(ProductActions.createTranslationSuccess, ProductActions.updateTranslationSuccess, (state, { productId, translation }) => {
    const translations = [...state.products[productId].translations];
    const translationIndex = translations.findIndex(t => t.language === translation.language);
    translations.splice(translationIndex, 1, translation);

    return {
      ...state,
      products: {
        ...state.products,
        [productId]: {
          ...state.products[productId],
          translations,
        },
      },
      loading: false,
    };
  }),

  on(ProductActions.addPrice, (state, { productId, price }) => ({
    ...state,
    products: {
      ...state.products,
      [productId]: {
        ...state.products[productId],
        prices: [...state.products[productId].prices, price],
      },
    },
  })),

  on(ProductActions.removeNonSavedPrice, (state, { productId, index }) => {
    const prices = [...state.products[productId].prices];
    prices.splice(index, 1);

    return {
      ...state,
      products: {
        ...state.products,
        [productId]: {
          ...state.products[productId],
          prices,
        },
      },
    };
  }),

  on(ProductActions.createPriceSuccess, (state, { productId, price, index }) => {
    const prices = [...state.products[productId].prices];
    prices.splice(index, 1, price);

    return {
      ...state,
      products: {
        ...state.products,
        [productId]: {
          ...state.products[productId],
          prices,
        },
      },
      loading: false,
    };
  }),

  on(ProductActions.updatePriceSuccess, (state, { productId, price, id }) => {
    const prices = [...state.products[productId].prices];
    const priceIndex = prices.findIndex(p => p.id === id);
    prices.splice(priceIndex, 1, price);

    return {
      ...state,
      products: {
        ...state.products,
        [productId]: {
          ...state.products[productId],
          prices,
        },
      },
      loading: false,
    };
  }),

  on(ProductActions.removePriceSuccess, (state, { productId, id }) => {
    const prices = [...state.products[productId].prices].filter(price => price.id !== id);

    return {
      ...state,
      products: {
        ...state.products,
        [productId]: {
          ...state.products[productId],
          prices,
        },
      },
      loading: false,
    };
  }),
);
