import { createAction, props } from "@ngrx/store";
import { CreatePriceDto, CreateProductDto, CreateTranslationDto, Price, Product, Translation } from "src/app/services/api";

export const create = createAction("[Product] Create", props<{ product: CreateProductDto }>());

export const createSuccess = createAction("[Product] Create Success", props<{ product: Product }>());

export const createFailure = createAction("[Product] Create Failure");

export const loadAll = createAction("[Product] Load All");

export const loadAllSuccess = createAction("[Product] Load All Success", props<{ products: Product[] }>());

export const loadAllFailure = createAction("[Product] Load All Failure");

export const loadOne = createAction("[Product] Load One", props<{ id: number }>());

export const loadOneSuccess = createAction("[Product] Load One Success", props<{ product: Product }>());

export const loadOneFailure = createAction("[Product] Load One Failure");

export const loadByCategory = createAction("[Product] Load By Category", props<{ categoryId: number }>());

export const loadByCategorySuccess = createAction("[Product] Load By Category Success", props<{ products: Product[] }>());

export const loadByCategoryFailure = createAction("[Product] Load By Category Failure");

export const update = createAction("[Product] Update", props<{ id: number; product: CreateProductDto }>());

export const updateSuccess = createAction("[Product] Update Success", props<{ product: Product }>());

export const updateFailure = createAction("[Product] Update Failure");

export const remove = createAction("[Product] Remove", props<{ id: number }>());

export const removeSuccess = createAction("[Product] Remove Success", props<{ id: number }>());

export const removeFailure = createAction("[Product] Remove Failure");

export const select = createAction("[Product] Select", props<{ id: number }>());

export const deselect = createAction("[Product] Deselect");

export const addTranslation = createAction("[Product] Add Translation", props<{ id: number; translation: Translation }>());

export const createTranslation = createAction(
  "[Product] Create Translation",
  props<{ productId: number; translation: CreateTranslationDto }>(),
);

export const createTranslationSuccess = createAction(
  "[Product] Create Translation Success",
  props<{ productId: number; translation: Translation }>(),
);

export const createTranslationFailure = createAction("[Product] Create Translation Failure");

export const updateTranslation = createAction(
  "[Product] Update Translation",
  props<{ id: number; productId: number; translation: CreateTranslationDto }>(),
);

export const updateTranslationSuccess = createAction(
  "[Product] Update Translation Success",
  props<{ id: number; productId: number; translation: Translation }>(),
);

export const updateTranslationFailure = createAction("[Product] Update Translation Failure");

export const removeNonSavedTranslation = createAction(
  "[Product] Remove Non Saved Translation",
  props<{ productId: number; translation: Translation }>(),
);

export const removeTranslation = createAction("[Product] Remove Translation", props<{ productId: number; translation: Translation }>());

export const removeTranslationSuccess = createAction(
  "[Product] Remove Translation Success",
  props<{ productId: number; translation: Translation }>(),
);

export const removeTranslationFailure = createAction("[Product] Remove Translation Failure");

export const addPrice = createAction("[Product] Add Price", props<{ productId: number; price: Price }>());

export const removeNonSavedPrice = createAction("[Product] Remove Non Saved Price", props<{ productId: number; index: number }>());

export const createPrice = createAction("[Product] Create Price", props<{ productId: number; index: number; price: CreatePriceDto }>());

export const createPriceSuccess = createAction(
  "[Product] Create Price Success",
  props<{ productId: number; index: number; price: CreatePriceDto }>(),
);

export const createPriceFailure = createAction("[Product] Create Price Failure");

export const updatePrice = createAction("[Product] Update Price", props<{ id: number; productId: number; price: CreatePriceDto }>());

export const updatePriceSuccess = createAction(
  "[Product] Update Price Success",
  props<{ id: number; productId: number; price: CreatePriceDto }>(),
);

export const updatePriceFailure = createAction("[Product] Update Price Failure");

export const removePrice = createAction("[Product] Remove Price", props<{ id: number; productId: number }>());

export const removePriceSuccess = createAction("[Product] Remove Price Success", props<{ id: number; productId: number }>());

export const removePriceFailure = createAction("[Product] Remove Price Failure");

export const reset = createAction("[Product] Reset");
