import { Establishment } from "src/app/services/api";

export interface Establishments {
  [id: number]: Establishment;
}

export interface EstablishmentState {
  establishments: Establishments;
  loading: boolean;
}

export const initialState: EstablishmentState = {
  establishments: {},
  loading: false,
};
