import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as MenuActions from "./actions";
import { MenuCategory } from "src/app/services/api";

export const menuReducer = createReducer(
  initialState,

  on(MenuActions.reset, () => initialState),

  on(
    MenuActions.create,
    MenuActions.loadAll,
    MenuActions.loadOne,
    MenuActions.update,
    MenuActions.remove,
    MenuActions.removeMenuCategory,
    MenuActions.loadByEstablishment,
    MenuActions.removeMenuCategoryProduct,
    MenuActions.getMenuTimetables,
    MenuActions.createMenuTimetables,
    MenuActions.removeMenuTimetables,
    state => ({ ...state, loading: true }),
  ),

  on(
    MenuActions.loadAllFailure,
    MenuActions.loadOneFailure,
    MenuActions.createFailure,
    MenuActions.updateFailure,
    MenuActions.removeFailure,
    MenuActions.saveMenuCategoryFailure,
    MenuActions.removeMenuCategoryFailure,
    MenuActions.loadByEstablishmentFailure,
    MenuActions.removeMenuCategoryProductFailure,
    MenuActions.getMenuTimetablesFailure,
    MenuActions.createMenuTimetablesFailure,
    MenuActions.removeMenuTimetablesFailure,
    state => ({ ...state, loading: false }),
  ),

  on(MenuActions.loadAllSuccess, MenuActions.loadByEstablishmentSuccess, (state, { menus }) => {
    const entities = {};
    menus.forEach(menu => (entities[menu.id] = menu));
    return {
      ...state,
      menus: entities,
      loading: false,
    };
  }),

  on(MenuActions.createSuccess, MenuActions.loadOneSuccess, MenuActions.updateSuccess, (state, { menu }) => ({
    ...state,
    menus: {
      ...state.menus,
      [menu.id]: menu,
    },
    loading: false,
  })),

  on(MenuActions.addMenuCategory, (state, { menuId, menuCategory }) => ({
    ...state,
    menus: {
      ...state.menus,
      [menuId]: {
        ...state.menus[menuId],
        menuCategories: [...state.menus[menuId].menuCategories, menuCategory],
      },
    },
  })),

  on(MenuActions.reorderMenuCategories, (state, { menuId, menuCategories }) => ({
    ...state,
    menus: {
      ...state.menus,
      [menuId]: {
        ...state.menus[menuId],
        menuCategories,
      },
    },
  })),

  on(MenuActions.removeNonSavedCategory, (state, { menuId, categoryId }) => {
    const menuCategories = [...state.menus[menuId].menuCategories].filter(mc => mc.category.id !== categoryId);

    return {
      ...state,
      menus: {
        ...state.menus,
        [menuId]: {
          ...state.menus[menuId],
          menuCategories,
        },
      },
    };
  }),

  on(MenuActions.removeNonSavedMenuCategoryProduct, (state, { menuId, menuCategoryOrder, menuCategoryProductOrder }) => {
    const menuCategories = [...state.menus[menuId].menuCategories];
    const menuCategoryIndex = menuCategories.findIndex(mc => mc.order === menuCategoryOrder);
    const menuCategory = menuCategories[menuCategoryIndex];
    const menuCategoryProducts = [...menuCategory.menuCategoryProducts].filter(mcp => mcp.order !== menuCategoryProductOrder);
    menuCategories[menuCategoryIndex] = { ...menuCategory, menuCategoryProducts };

    return {
      ...state,
      menus: {
        ...state.menus,
        [menuId]: {
          ...state.menus[menuId],
          menuCategories,
        },
      },
      loading: false,
    };
  }),

  on(MenuActions.removeMenuCategoryProductSuccess, (state, { menuId, menuCategoryId, menuCategoryProductId }) => {
    const menuCategories = [...state.menus[menuId].menuCategories];
    const menuCategoryIndex = menuCategories.findIndex(mc => mc.id === menuCategoryId);
    const menuCategory = menuCategories[menuCategoryIndex];
    const menuCategoryProducts = [...menuCategory.menuCategoryProducts].filter(mcp => mcp.id !== menuCategoryProductId);
    menuCategories[menuCategoryIndex] = { ...menuCategory, menuCategoryProducts };

    return {
      ...state,
      menus: {
        ...state.menus,
        [menuId]: {
          ...state.menus[menuId],
          menuCategories,
        },
      },
      loading: false,
    };
  }),

  on(
    MenuActions.saveMenuCategorySuccess,
    MenuActions.reorderMenuCategoryProducts,
    MenuActions.updateMenuCategory,
    (state, { menuId, menuCategory }) => {
      const menuCategories = [...state.menus[menuId].menuCategories];
      const index = menuCategories.findIndex(mc => mc.category.id === menuCategory.category.id);
      if (index === -1) menuCategories.push(menuCategory);
      else menuCategories.splice(index, 1, menuCategory);

      return {
        ...state,
        menus: {
          ...state.menus,
          [menuId]: {
            ...state.menus[menuId],
            menuCategories,
          },
        },
        loading: false,
      };
    },
  ),

  on(MenuActions.addMenuCategoryProduct, (state, { menuId, categoryId, menuCategoryProduct }) => {
    const menuCategories = [...state.menus[menuId].menuCategories];
    const index = menuCategories.findIndex(mc => mc.category.id === categoryId);
    const menuCategory: MenuCategory = JSON.parse(JSON.stringify(menuCategories[index]));
    menuCategory.menuCategoryProducts.push(menuCategoryProduct);
    if (index === -1) menuCategories.push(menuCategory);
    else menuCategories.splice(index, 1, menuCategory);

    return {
      ...state,
      menus: {
        ...state.menus,
        [menuId]: {
          ...state.menus[menuId],
          menuCategories,
        },
      },
    };
  }),

  on(MenuActions.removeMenuCategorySuccess, (state, { menuId, id }) => {
    const menuCategories = [...state.menus[menuId].menuCategories].filter(mc => mc.id !== id);

    return {
      ...state,
      menus: {
        ...state.menus,
        [menuId]: {
          ...state.menus[menuId],
          menuCategories,
        },
      },
      loading: false,
    };
  }),

  on(MenuActions.saveMenuCategoryProductSuccess, (state, { menuCategoryId, menuCategoryProduct, menuId }) => {
    const menuCategories = [...state.menus[menuId].menuCategories];
    const menuCategoryIndex = menuCategories.findIndex(mc => mc.id === menuCategoryId);
    const menuCategory: MenuCategory = JSON.parse(JSON.stringify(menuCategories[menuCategoryIndex]));
    const menuCategoryProductIndex = menuCategory.menuCategoryProducts.findIndex(mcp => mcp.product.id === menuCategoryProduct.product.id);

    if (menuCategoryProductIndex === -1) {
      if (menuCategory.menuCategoryProducts) menuCategory.menuCategoryProducts.push(menuCategoryProduct);
      else menuCategory.menuCategoryProducts = [menuCategoryProduct];
    } else {
      menuCategory.menuCategoryProducts.splice(menuCategoryProductIndex, 1, menuCategoryProduct);
    }

    menuCategories.splice(menuCategoryIndex, 1, menuCategory);

    return {
      ...state,
      menus: {
        ...state.menus,
        [menuId]: {
          ...state.menus[menuId],
          menuCategories,
        },
      },
    };
  }),

  on(MenuActions.getMenuTimetablesSuccess, MenuActions.createMenuTimetablesSuccess, (state, { menuId, timetables }) => ({
    ...state,
    timetables: {
      ...state.timetables,
      [menuId]: timetables,
    },
    loading: false,
  })),

  on(MenuActions.removeMenuTimetablesSuccess, (state, { menuId }) => ({
    ...state,
    timetables: {
      ...state.timetables,
      [menuId]: [],
    },
    loading: false,
  })),
);
