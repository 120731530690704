import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { Router, RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { ApiModule, Configuration } from "./services/api";
import { environment } from "src/environments/environment";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { AuthInterceptorService } from "./services/interceptors/auth-interceptor/auth-interceptor.service";
import { refreshAccessTokenProviderFactory } from "./services/auth/refresh.factory";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireStorageModule, BUCKET } from "@angular/fire/compat/storage";
import { AngularFireAnalyticsModule, APP_NAME, APP_VERSION, ScreenTrackingService } from "@angular/fire/compat/analytics";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { ActionReducer, MetaReducer, Store, StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { EffectsModule } from "@ngrx/effects";
import { effects, reducers, RootState } from "./store";
import { localStorageSync } from "ngrx-store-localstorage";
import { SentryService } from "./services/sentry/sentry.service";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import * as Sentry from "@sentry/angular";
import { DEFAULT_LANGUAGE } from "./constants/app";
import { createTranslateLoader } from "./services/i18n/translate-loader";
import { StripeModule } from "stripe-angular";
import { StoreRouterConnectingModule } from "@ngrx/router-store";

export const setApiConfig = (): Configuration => new Configuration({ basePath: environment.baseApi });

export const localStorageReduxSync = (reducer: ActionReducer<RootState>): ActionReducer<any> => localStorageSync({ keys: ["app", "user"], rehydrate: true })(reducer);

const metaReducers: MetaReducer<any, any>[] = [localStorageReduxSync];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        TranslateModule.forRoot({
            defaultLanguage: DEFAULT_LANGUAGE.toLowerCase(),
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        AppRoutingModule,
        AngularFireModule.initializeApp(environment.firebase, "com-ohmymenu-admin"),
        StripeModule.forRoot(""),
        AngularFireStorageModule,
        AngularFireAnalyticsModule,
        AngularFireAuthModule,
        HttpClientModule,
        ReactiveFormsModule,
        ApiModule.forRoot(setApiConfig),
        StoreModule.forRoot(reducers, { metaReducers }),
        StoreRouterConnectingModule.forRoot(),
        StoreDevtoolsModule.instrument({ maxAge: 20, logOnly: environment.production }),
        EffectsModule.forRoot(effects),
    ],
    providers: [
        StatusBar,
        SplashScreen,
        ScreenTrackingService,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: APP_INITIALIZER, useFactory: refreshAccessTokenProviderFactory, deps: [Store, SentryService], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
        { provide: Sentry.TraceService, deps: [Router] },
        { provide: ErrorHandler, useClass: SentryService },
        { provide: BUCKET, useValue: environment.bucket },
        { provide: APP_NAME, useValue: "com-ohmymenu-admin" },
        { provide: APP_VERSION, useValue: environment.release },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
