import { Product } from "src/app/services/api";

export interface Products {
  [id: number]: Product;
}

export interface ProductState {
  products: Products;
  selected: number;
  loading: boolean;
}

export const initialState: ProductState = {
  products: {},
  selected: null,
  loading: false,
};
