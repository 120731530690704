/**
 * Oh My Menu
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.23
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Translation } from './translation';
import { Category } from './category';
import { User } from './user';
import { Price } from './price';
import { MenuCategoryProduct } from './menu-category-product';


export interface Product { 
    id: number;
    user: User;
    category: Category;
    menuCategoryProducts: Array<MenuCategoryProduct>;
    prices: Array<Price>;
    translations: Array<Translation>;
    name: string;
    description: string;
    image: string;
    allergens: Array<Product.AllergensEnum>;
    createdAt: string;
    updatedAt: string;
}
export namespace Product {
    export type AllergensEnum = 'CELERY' | 'CRUSTACEANS' | 'EGG' | 'FISH' | 'GLUTEN' | 'LUPIN' | 'MILK' | 'MOLLUSCS' | 'MUSTARD' | 'PEANUTS' | 'SESAME' | 'SOYBEANS' | 'TREENUTS';
    export const AllergensEnum = {
        Celery: 'CELERY' as AllergensEnum,
        Crustaceans: 'CRUSTACEANS' as AllergensEnum,
        Egg: 'EGG' as AllergensEnum,
        Fish: 'FISH' as AllergensEnum,
        Gluten: 'GLUTEN' as AllergensEnum,
        Lupin: 'LUPIN' as AllergensEnum,
        Milk: 'MILK' as AllergensEnum,
        Molluscs: 'MOLLUSCS' as AllergensEnum,
        Mustard: 'MUSTARD' as AllergensEnum,
        Peanuts: 'PEANUTS' as AllergensEnum,
        Sesame: 'SESAME' as AllergensEnum,
        Soybeans: 'SOYBEANS' as AllergensEnum,
        Treenuts: 'TREENUTS' as AllergensEnum
    };
}


