import { Menu, TimetableDto } from "src/app/services/api";

export interface Menus {
  [id: number]: Menu;
}

export interface MenuTimetables {
  [id: number]: TimetableDto[];
}

export interface MenuState {
  menus: Menus;
  timetables: MenuTimetables;
  loading: boolean;
}

export const initialState: MenuState = {
  menus: {},
  timetables: {},
  loading: false,
};
