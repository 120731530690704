import { Injectable } from "@angular/core";
import { createEffect, ofType } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, mergeMap, switchMap, tap, withLatestFrom } from "rxjs/operators";
import { RootState } from "..";
import * as CategoryActions from "./actions";
import * as FromUser from "src/app/store/user/selectors";
import { NavController } from "@ionic/angular";
import { ROUTES } from "src/app/constants/routes";
import { ToastService } from "src/app/services/toast/toast.service";
import { CategoriesService, TranslationsService } from "src/app/services/api";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class CategoryEffects {
  public loadAll$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.loadAll),
      withLatestFrom(this.store.select(FromUser.selectUser)),
      switchMap(([_, user]) => this.categoryService.categoryControllerFindByUser(user.id).pipe(
        map(categories => CategoryActions.loadAllSuccess({ categories })),
        catchError(() => of(CategoryActions.loadAllFailure())),
      )),
    ),
  );

  public loadOne$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.loadOne),
      mergeMap(action =>
        this.categoryService.categoryControllerFindOne(action.id).pipe(
          map(category => CategoryActions.loadOneSuccess({ category })),
          catchError(() => of(CategoryActions.loadOneFailure())),
        ),
      ),
    ),
  );

  public create$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.create),
      mergeMap(action =>
        this.categoryService.categoryControllerCreate(action.category).pipe(
          map(category => CategoryActions.createSuccess({ category })),
          catchError(() => of(CategoryActions.createFailure())),
        ),
      ),
    ),
  );

  public update$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.update),
      mergeMap(action =>
        this.categoryService.categoryControllerUpdate(action.category, action.id).pipe(
          map(category => CategoryActions.updateSuccess({ category })),
          tap(() => of(this.toastService.presentToast(this.i18n.instant("Toast.successfullyUpdated"), "dark"))),
          catchError(() => of(CategoryActions.updateFailure())),
        ),
      ),
    ),
  );

  public remove$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.remove),
      mergeMap(action =>
        this.categoryService.categoryControllerRemove(action.id).pipe(
          map(() => CategoryActions.removeSuccess({ id: action.id })),
          tap(() => of(this.navController.navigateBack([ROUTES.CATEGORIES]))),
          catchError(() => of(CategoryActions.createFailure())),
        ),
      ),
    ),
  );

  public createTranslation$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.createTranslation),
      mergeMap(action =>
        this.i18nService.translationControllerCreate(action.translation).pipe(
          map(translation => CategoryActions.createTranslationSuccess({ id: action.id, translation })),
          catchError(() => of(CategoryActions.createTranslationFailure())),
        ),
      ),
    ),
  );

  public updateTranslation$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.updateTranslation),
      mergeMap(action =>
        this.i18nService.translationControllerUpdate(action.translation, action.id).pipe(
          map(translation => CategoryActions.updateTranslationSuccess({ id: action.id, categoryId: action.categoryId, translation })),
          catchError(() => of(CategoryActions.updateTranslationFailure())),
        ),
      ),
    ),
  );

  public removeTranslation$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(CategoryActions.removeTranslation),
      mergeMap(action =>
        this.i18nService.translationControllerRemove(action.translation.id).pipe(
          map(() => CategoryActions.removeTranslationSuccess({ ...action })),
          catchError(() => of(CategoryActions.removeTranslationFailure())),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private i18n: TranslateService,
    private categoryService: CategoriesService,
    private i18nService: TranslationsService,
    private navController: NavController,
    private toastService: ToastService,
  ) {}
}
