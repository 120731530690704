import { createAction, props } from "@ngrx/store";
import { CreateEstablishmentTimetables, Menu, MenuCategory, MenuCategoryProduct, TimetableDto, Translation } from "src/app/services/api";

export const create = createAction(
  "[Menu] Create",
  props<{ menu: Menu; establishmentId?: number; translations: Partial<Translation>[] }>(),
);
export const createSuccess = createAction("[Menu] Create Success", props<{ menu: Menu }>());
export const createFailure = createAction("[Menu] Create Failure");

export const loadAll = createAction("[Menu] Load All");
export const loadAllSuccess = createAction("[Menu] Load All Success", props<{ menus: Menu[] }>());
export const loadAllFailure = createAction("[Menu] Load All Failure");

export const loadOne = createAction("[Menu] Load One", props<{ id: number }>());
export const loadOneSuccess = createAction("[Menu] Load One Success", props<{ menu: Menu }>());
export const loadOneFailure = createAction("[Menu] Load One Failure");

export const loadByEstablishment = createAction("[Menu] Load By Establishment", props<{ establishmentId: number }>());
export const loadByEstablishmentSuccess = createAction("[Menu] Load By Establishment Success", props<{ menus: Menu[] }>());
export const loadByEstablishmentFailure = createAction("[Menu] Load By Establishment Failure");

export const update = createAction("[Menu] Update", props<{ id: number; menu: Menu; translations: Partial<Translation>[] }>());
export const updateSuccess = createAction("[Menu] Update Success", props<{ menu: Menu }>());
export const updateFailure = createAction("[Menu] Update Failure");

export const remove = createAction("[Menu] Remove", props<{ id: number; establishmentId?: number }>());
export const removeSuccess = createAction("[Menu] Remove Success", props<{ id: number }>());
export const removeFailure = createAction("[Menu] Remove Failure");

export const addMenuCategory = createAction("[Menu] Add Menu Category", props<{ menuId: number; menuCategory: MenuCategory }>());

export const removeNonSavedCategory = createAction(
  "[Menu] Remove Non Saved Menu Category",
  props<{ menuId: number; categoryId: number }>(),
);

export const saveMenuCategorySuccess = createAction(
  "[Menu] Save Menu Category Success",
  props<{ menuId: number; menuCategory: MenuCategory }>(),
);

export const saveMenuCategoryFailure = createAction("[Menu] Save Menu Category Failure");

export const updateMenuCategory = createAction(
  "[Menu] Update Menu Category",
  props<{ menuId: number; menuCategory: MenuCategory; menuCategoryProducts: MenuCategoryProduct[] }>(),
);

export const updateMenuCategoryFailure = createAction("[Menu] Update Menu Category Failure");

export const removeMenuCategory = createAction("[Menu] Remove Menu Category", props<{ id: number; menuId: number }>());
export const removeMenuCategorySuccess = createAction("[Menu] Remove Menu Category Success", props<{ id: number; menuId: number }>());
export const removeMenuCategoryFailure = createAction("[Menu] Remove Menu Category Failure");

export const reorderMenuCategories = createAction(
  "[Menu] Reorder Menu Categories",
  props<{ menuId: number; menuCategories: MenuCategory[] }>(),
);

export const addMenuCategoryProduct = createAction(
  "[Menu] Add Menu Category Product",
  props<{ menuId: number; categoryId: number; menuCategoryProduct: MenuCategoryProduct }>(),
);

export const removeNonSavedMenuCategoryProduct = createAction(
  "[Menu] Remove Non Saved Menu Category Product",
  props<{ menuId: number; menuCategoryOrder: number; menuCategoryProductOrder: number }>(),
);

export const removeMenuCategoryProduct = createAction(
  "[Menu] Remove Menu Category Product",
  props<{ menuId: number; menuCategoryId: number; menuCategoryProductId: number }>(),
);

export const removeMenuCategoryProductSuccess = createAction(
  "[Menu] Remove Menu Category Product Success",
  props<{ menuId: number; menuCategoryId: number; menuCategoryProductId: number }>(),
);

export const removeMenuCategoryProductFailure = createAction("[Menu] Remove Menu Category Product Failure");

export const reorderMenuCategoryProducts = createAction(
  "[Menu] Reorder Menu Category Products",
  props<{ menuId: number; menuCategory: MenuCategory }>(),
);

export const saveMenuCategoryProduct = createAction(
  "[Menu] Save Menu Category Product",
  props<{ menuId: number; menuCategory: MenuCategory; menuCategoryProduct: MenuCategoryProduct }>(),
);

export const saveMenuCategoryProductSuccess = createAction(
  "[Menu] Save Menu Category Product Success",
  props<{ menuId: number; menuCategoryId: number; menuCategoryProduct: MenuCategoryProduct }>(),
);

export const saveMenuCategoryProductFailure = createAction("[Menu] Save Menu Category Product Failure");

export const updateMenuCategoryProduct = createAction(
  "[Menu] Update Menu Category Product",
  props<{ menuId: number; menuCategory: MenuCategory; menuCategoryProduct: MenuCategoryProduct }>(),
);

export const updateMenuCategoryProductSuccess = createAction(
  "[Menu] Update Menu Category Product Success",
  props<{ menuId: number; menuCategoryId: number; menuCategoryProduct: MenuCategoryProduct }>(),
);

export const updateMenuCategoryProductFailure = createAction("[Menu] Update Menu Category Product Failure");

export const getMenuTimetables = createAction("[Menu] Get Menu Timetables", props<{ establishmentId: number; menuId: number }>());
export const getMenuTimetablesSuccess = createAction(
  "[Menu] Get Menu Timetables Success",
  props<{ menuId: number; timetables: TimetableDto[] }>(),
);
export const getMenuTimetablesFailure = createAction("[Menu] Get Menu Timetables Failure");

export const createMenuTimetables = createAction(
  "[Menu] Create Menu Timetables",
  props<{ establishmentId: number; menuId: number; timetables: CreateEstablishmentTimetables }>(),
);
export const createMenuTimetablesSuccess = createAction(
  "[Menu] Create Menu Timetables Success",
  props<{ menuId: number; timetables: TimetableDto[] }>(),
);
export const createMenuTimetablesFailure = createAction("[Menu] Create Menu Timetables Failure");

export const removeMenuTimetables = createAction("[Menu] Remove Menu Timetables", props<{ establishmentId: number; menuId: number }>());
export const removeMenuTimetablesSuccess = createAction("[Menu] Remove Menu Timetables Success", props<{ menuId: number }>());
export const removeMenuTimetablesFailure = createAction("[Menu] Remove Menu Timetables Failure");

export const reset = createAction("[Menu] Reset");
