import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { ROUTES } from "./constants/routes";

const routes: Routes = [
  {
    path: ROUTES.LOGIN,
    loadChildren: () => import("./pages/login/login.module").then(m => m.LoginPageModule),
  },
  {
    path: ROUTES.REGISTER,
    loadChildren: () => import("./pages/register/register.module").then(m => m.RegisterPageModule),
  },
  {
    path: ROUTES.CONFIRM_EMAIL,
    loadChildren: () => import("./pages/confirm-email/confirm-email.module").then(m => m.ConfirmEmailPageModule),
  },
  {
    path: ROUTES.RESET_PASSWORD,
    loadChildren: () => import("./pages/reset-password/reset-password.module").then(m => m.ResetPasswordPageModule),
  },
  {
    path: ROUTES.PANEL,
    loadChildren: () => import("./pages/panel/panel.module").then(m => m.PanelPageModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
