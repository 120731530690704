import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, mergeMap, switchMap } from "rxjs/operators";
import { ToastService } from "../../toast/toast.service";
import { AlertController, NavController } from "@ionic/angular";
import { ROUTES } from "src/app/constants/routes";
import { Store } from "@ngrx/store";
import { RootState } from "src/app/store";
import { AuthService, UsersService } from "../../api";
import * as UserActions from "src/app/store/user/actions";
import * as FromUser from "src/app/store/user/selectors";
import { TranslateService } from "@ngx-translate/core";

const NO_INTERCEPTOR_ROUTES = [ROUTES.LOGIN, "refresh"];

@Injectable({ providedIn: "root" })
export class AuthInterceptorService implements HttpInterceptor {
  private accessToken: string;
  private refreshToken: string;

  constructor(
    private store: Store<RootState>,
    private authService: AuthService,
    private userService: UsersService,
    private navController: NavController,
    private toastService: ToastService,
    private alertController: AlertController,
    private injector: Injector,
  ) {
    this.store.select(FromUser.selectAccessToken).subscribe(accessToken => {
      this.accessToken = accessToken;
    });
    this.store.select(FromUser.selectRefreshToken).subscribe(refreshToken => {
      this.refreshToken = refreshToken;
    });
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.accessToken) {
      req = req.clone({ setHeaders: { authorization: `Bearer ${this.accessToken}` } });
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 && !NO_INTERCEPTOR_ROUTES.some(route => req.url.includes(route))) {
          return this.authService.authenticationControllerRefresh({ refreshToken: this.refreshToken }).pipe(
            mergeMap(tokens => {
              this.store.dispatch(UserActions.refreshTokenSuccess(tokens));
              return this.userService.userControllerFindOne().pipe(
                switchMap(user => {
                  this.store.dispatch(UserActions.loadSuccess({ user }));
                  return next.handle(req.clone({ setHeaders: { authorization: `Bearer ${this.accessToken}` } }));
                }),
                catchError(e => {
                  this.store.dispatch(UserActions.reset());
                  return this.catchError(e);
                }),
              );
            }),
            catchError(e => {
              this.store.dispatch(UserActions.reset());
              return this.catchError(e);
            }),
          );
        } else {
          const i18n: TranslateService = this.injector.get(TranslateService);

          switch (error.error?.message) {
            case "Unauthorized":
              this.toastService.presentToast(i18n.instant("Message.unauthorized"), "danger");
              break;
            case "EMAIL_ALREADY_IN_USE":
              this.toastService.presentToast(i18n.instant("Message.emailAlreadyInUse"), "danger");
              break;
            case "ACCOUNT_DOES_NOT_EXISTS":
              this.toastService.presentToast(i18n.instant("Message.accountDoesNotExists"), "danger");
              break;
            case "INVALID_PASSWORD":
              this.toastService.presentToast(i18n.instant("Message.invalidPassword"), "danger");
              break;
            case "USER_NOT_YET_ACTIVATED":
              this.alertController
                .create({
                  backdropDismiss: false,
                  header: i18n.instant("Message.userNotYetActivated"),
                  message: i18n.instant("Message.userNotYetActivatedMessage"),
                  mode: "ios",
                  buttons: [
                    {
                      text: i18n.instant("Buttons.understood"),
                      role: "Cancel",
                      cssClass: ["alert-regular-button"],
                    },
                  ],
                })
                .then(async alert => {
                  await alert.present();
                });
              break;
            default:
              this.toastService.presentToast(error.error.message || error.statusText, "warning");
          }

          return this.catchError(error, null);
        }
      }),
    );
  }

  private catchError(error: any, redirectTo: string[] = [ROUTES.LOGIN]): Observable<any> {
    if (redirectTo) {
      this.navController.navigateRoot([ROUTES.LOGIN]);
    }

    return throwError(error);
  }
}
