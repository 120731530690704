import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as CategoryActions from "./actions";

export const categoryReducer = createReducer(
  initialState,

  on(CategoryActions.reset, () => initialState),

  on(
    CategoryActions.create,
    CategoryActions.loadAll,
    CategoryActions.loadOne,
    CategoryActions.update,
    CategoryActions.remove,
    CategoryActions.createTranslation,
    CategoryActions.updateTranslation,
    CategoryActions.removeTranslation,
    state => ({ ...state, loading: true }),
  ),

  on(
    CategoryActions.loadAllFailure,
    CategoryActions.loadOneFailure,
    CategoryActions.createFailure,
    CategoryActions.updateFailure,
    CategoryActions.removeFailure,
    CategoryActions.createTranslationFailure,
    CategoryActions.updateTranslationFailure,
    CategoryActions.removeTranslationFailure,
    state => ({ ...state, loading: false }),
  ),

  on(CategoryActions.select, (state, { id }) => ({ ...state, selected: id })),

  on(CategoryActions.deselect, state => ({ ...state, selected: null })),

  on(CategoryActions.loadAllSuccess, (state, { categories }) => {
    const entities = {};
    categories.forEach(category => (entities[category.id] = category));

    return {
      ...state,
      categories: entities,
      loading: false,
    };
  }),

  on(CategoryActions.createSuccess, CategoryActions.loadOneSuccess, CategoryActions.updateSuccess, (state, { category }) => {
    const oldOne = state.categories[category.id];

    return {
      ...state,
      categories: {
        ...state.categories,
        [category.id]: { ...oldOne, ...category },
      },
      selected: category.id,
      loading: false,
    };
  }),

  on(CategoryActions.removeSuccess, (state, { id }) => {
    const categories = Object.values(state.categories).filter(category => category.id !== id);
    const entities = {};
    categories.forEach(category => (entities[category.id] = category));

    return {
      ...state,
      categories: entities,
      loading: false,
    };
  }),

  on(CategoryActions.addTranslation, (state, { id, translation }) => ({
    ...state,
    categories: {
      ...state.categories,
      [id]: {
        ...state.categories[id],
        translations: [...state.categories[id].translations, translation],
      },
    },
  })),

  on(CategoryActions.removeNonSavedTranslation, CategoryActions.removeTranslationSuccess, (state, { categoryId, translation }) => ({
    ...state,
    categories: {
      ...state.categories,
      [categoryId]: {
        ...state.categories[categoryId],
        translations: [...state.categories[categoryId].translations].filter(t => t.language !== translation.language),
      },
    },
    loading: false,
  })),

  on(CategoryActions.createTranslationSuccess, (state, { id, translation }) => {
    const translations = [...state.categories[id].translations];
    const translationIndex = translations.findIndex(t => t.language === translation.language);
    translations.splice(translationIndex, 1, translation);

    return {
      ...state,
      categories: {
        ...state.categories,
        [id]: {
          ...state.categories[id],
          translations,
        },
      },
      loading: false,
    };
  }),

  on(CategoryActions.updateTranslationSuccess, (state, { categoryId, translation }) => {
    const translations = [...state.categories[categoryId].translations];
    const translationIndex = translations.findIndex(t => t.language === translation.language);
    translations.splice(translationIndex, 1, translation);

    return {
      ...state,
      categories: {
        ...state.categories,
        [categoryId]: {
          ...state.categories[categoryId],
          translations,
        },
      },
      loading: false,
    };
  }),
);
