import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as UserActions from "./actions";

export const userReducer = createReducer(
  initialState,

  on(UserActions.reset, () => initialState),

  on(
    UserActions.register,
    UserActions.login,
    UserActions.loginWithGoogle,
    UserActions.refreshToken,
    UserActions.resetPassword,
    UserActions.setNewPassword,
    UserActions.load,
    UserActions.confirmEmail,
    UserActions.updateUser,
    UserActions.changePassword,
    UserActions.deleteAccount,
    UserActions.contact,
    state => ({ ...state, loading: true }),
  ),

  on(
    UserActions.resetPasswordSuccess,
    UserActions.setNewPasswordFailure,
    UserActions.loginFailure,
    UserActions.registerFailure,
    UserActions.resetPasswordFailure,
    UserActions.confirmEmailFailure,
    UserActions.updateUserFailure,
    UserActions.changeUserSubscriptionFailure,
    UserActions.changePasswordFailure,
    UserActions.deleteAccountFailure,
    UserActions.contactSuccess,
    UserActions.contactFailure,
    state => ({
      ...state,
      loading: false,
    }),
  ),

  on(
    UserActions.registerSuccess,
    UserActions.loginSuccess,
    UserActions.refreshTokenSuccess,
    UserActions.setNewPasswordSuccess,
    UserActions.confirmEmailSuccess,
    (state, { accessToken, refreshToken }) => ({
      ...state,
      accessToken,
      refreshToken,
      loading: false,
    }),
  ),

  on(UserActions.loadSuccess, UserActions.updateUserSuccess, UserActions.changePasswordSuccess, (state, { user }) => ({
    ...state,
    user,
    loading: false,
  })),

  on(UserActions.storeAccessToken, (state, { accessToken }) => ({ ...state, accessToken })),

  on(UserActions.logout, UserActions.deleteAccountSuccess, () => initialState),
);
