/* eslint-disable max-len */
import { Injectable } from "@angular/core";
import { ModalController, NavController, ToastController } from "@ionic/angular";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";
import { forkJoin, Observable, of } from "rxjs";
import { catchError, map, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import { ROUTES } from "src/app/constants/routes";
import {
  MenuCategoriesService,
  MenusService,
  MenuCategoryProductsService,
  TranslationsService,
  Translation,
  MenuCategory,
  MenuCategoryProduct,
  EstablishmentsService,
} from "src/app/services/api";
import { RootState } from "..";
import * as MenuActions from "./actions";
import * as TranslationActions from "src/app/store/translation/actions";
import * as FromUser from "src/app/store/user/selectors";
import { ToastService } from "src/app/services/toast/toast.service";
import { TranslateService } from "@ngx-translate/core";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class MenuEffects {
  public loadAll$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.loadAll),
      withLatestFrom(this.store.select(FromUser.selectUser)),
      mergeMap(([_, user]) => this.menuService.menuControllerFindByUser(user.id)),
      map(menus => MenuActions.loadAllSuccess({ menus })),
      catchError(() => of(MenuActions.loadAllFailure())),
    ),
  );

  public loadOne$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.loadOne),
      mergeMap(action =>
        this.menuService.menuControllerFindOne(action.id).pipe(
          map(menu => MenuActions.loadOneSuccess({ menu })),
          catchError(() => of(MenuActions.loadOneFailure())),
        ),
      ),
    ),
  );

  public loadByEstablishment$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.loadByEstablishment),
      mergeMap(action =>
        this.menuService.menuControllerFindByEstablishment(action.establishmentId).pipe(
          map(menus => MenuActions.loadByEstablishmentSuccess({ menus })),
          catchError(() => of(MenuActions.loadByEstablishmentFailure())),
        ),
      ),
    ),
  );

  public create$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.create),
      map(action => {
        const { menuCategories, ...rest } = action.menu;
        return { ...action, menu: { ...rest }, menuCategories };
      }),
      mergeMap(action =>
        this.menuService.menuControllerCreate(action.menu).pipe(
          mergeMap(menu =>
            (!action.translations.length ? of([]) : forkJoin([
              ...action.translations.map(translation =>
                this.translationService.translationControllerCreate({
                  menuId: menu.id,
                  language: translation.language,
                  value: translation.value,
                  description: translation.description,
                }).pipe(catchError(() => of(TranslationActions.createFailure()))),
              ),
            ])).pipe(
              mergeMap((translations: Translation[]) =>
                (!action.menuCategories.length ? of([]) : forkJoin([
                  ...action.menuCategories.map(menuCategory =>
                    this.menuCategoryService.menuCategoryControllerCreate({
                      menu,
                      active: menuCategory.active,
                      category: menuCategory.category,
                      order: menuCategory.order,
                    }).pipe(catchError(() => of(MenuActions.saveMenuCategoryFailure()))),
                  ),
                ])).pipe(
                  mergeMap((menuCategories: MenuCategory[]) =>
                    (!action.menuCategories.length ? of([]) : forkJoin([
                      ...menuCategories.map(menuCategory => {
                        const { menuCategoryProducts } = action.menuCategories.find(mc => mc.category.id === menuCategory.category.id);
                        return !menuCategoryProducts.length ? of([]) : menuCategoryProducts.map(menuCategoryProduct =>
                          this.menuCategoryProductService.menuCategoryProductControllerCreate({
                            menuCategory,
                            product: menuCategoryProduct.product,
                            order: menuCategoryProduct.order,
                          }).pipe(catchError(() => of(MenuActions.saveMenuCategoryFailure()))),
                        );
                      }),
                    ].flat())).pipe(
                      mergeMap((menuCategoryProducts: MenuCategoryProduct[]) => {
                        this.toast.presentToast(this.i18n.instant("Toast.successfullyCreated"), "dark");
                        if (action.establishmentId) {
                          this.navController.navigateRoot([ROUTES.ESTABLISHMENTS, action.establishmentId.toString(), ROUTES.MENUS, menu.id, ROUTES.EDIT]);
                        } else {
                          this.navController.navigateRoot([ROUTES.MENUS, menu.id, ROUTES.EDIT]);
                        }
                        return [
                          MenuActions.createSuccess({ menu }),
                          ...translations.map(translation => TranslationActions.createSuccess({ translation })),
                          ...menuCategories.map(menuCategory => MenuActions.saveMenuCategorySuccess({ menuCategory, menuId: menu.id })),
                          ...menuCategoryProducts.map(menuCategoryProduct => MenuActions.saveMenuCategoryProductSuccess({ menuCategoryProduct, menuCategoryId: menuCategoryProduct.menuCategory.id, menuId: menu.id })),
                        ];
                      }),
                    ),
                  ),
                ),
              ),
              catchError(() => of(MenuActions.createFailure())),
            ),
          ),
        ),
      ),
    ),
  );

  public update$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.update),
      map(action => {
        const { menuCategories, ...rest } = action.menu;
        return { ...action, menu: { ...rest }, menuCategories };
      }),
      mergeMap(action =>
        this.menuService.menuControllerUpdate(action.menu, action.id).pipe(
          mergeMap(menu =>
            (!action.translations.length ? of([]) : forkJoin([
              ...action.translations.map((translation: Translation) =>
                translation.id
                  ? this.translationService.translationControllerUpdate(translation, translation.id)
                  : this.translationService.translationControllerCreate({ ...translation, menuId: menu.id }),
              ),
            ])).pipe(
              mergeMap((translations: Translation[]) =>
                (!action.menuCategories.length ? of([]) : forkJoin([
                  ...action.menuCategories.map(menuCategory =>
                    menuCategory.id
                      ? this.menuCategoryService.menuCategoryControllerUpdate({...menuCategory, menuCategoryProducts: undefined }, menuCategory.id).pipe(
                        map(response => ({ ...response, menuCategoryProducts: menuCategory.menuCategoryProducts })),
                      )
                      : this.menuCategoryService.menuCategoryControllerCreate({...menuCategory, menuCategoryProducts: undefined }).pipe(
                        map(response => ({ ...response, menuCategoryProducts: menuCategory.menuCategoryProducts })),
                      ),
                  ),
                ])).pipe(
                  mergeMap((menuCategories: MenuCategory[]) =>
                    (!action.menuCategories.length ? of([]) : forkJoin([
                      ...action.menuCategories.map(menuCategory =>
                        (!menuCategory.menuCategoryProducts.length ? of([]) : menuCategory.menuCategoryProducts.map(menuCategoryProduct =>
                          menuCategoryProduct.id
                            ? this.menuCategoryProductService.menuCategoryProductControllerUpdate(menuCategoryProduct, menuCategoryProduct.id).pipe(
                              catchError(() => of(MenuActions.updateMenuCategoryProductFailure())),
                            )
                            : this.menuCategoryProductService.menuCategoryProductControllerCreate(
                              { ...menuCategoryProduct, menuCategory: { ...menuCategoryProduct.menuCategory, id: menuCategories.find(mc => mc.category.id === menuCategory.category.id)?.id } },
                            ).pipe(
                              catchError(() => of(MenuActions.saveMenuCategoryProductFailure())),
                            ),
                        )),
                      ),
                    ].flat())).pipe(
                      mergeMap((menuCategoryProducts: MenuCategoryProduct[]) => {
                        this.toast.presentToast(this.i18n.instant("Toast.successfullyUpdated"), "dark");
                        return [
                          MenuActions.updateSuccess({ menu }),
                          ...translations.map(translation => TranslationActions.createSuccess({ translation })),
                          ...menuCategories.map(menuCategory => MenuActions.saveMenuCategorySuccess({ menuCategory, menuId: menu.id })),
                          ...menuCategoryProducts.map(menuCategoryProduct => MenuActions.saveMenuCategoryProductSuccess({ menuCategoryProduct, menuCategoryId: menuCategoryProduct.menuCategory?.id, menuId: menu.id })),
                        ];
                      }),
                    ),
                  ),
                ),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  public remove$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.remove),
      mergeMap(action =>
        this.menuService.menuControllerRemove(action.id).pipe(
          map(() => MenuActions.removeSuccess({ id: action.id })),
          tap(() => {
            if (action.establishmentId) {
              this.navController.navigateBack([ROUTES.ESTABLISHMENTS, action.establishmentId.toString(), ROUTES.MENUS]);
            } else {
              this.navController.navigateBack([ROUTES.MENUS]);
            }
          }),
          catchError(() => of(MenuActions.removeFailure())),
        ),
      ),
    ),
  );

  public removeMenuCategory$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.removeMenuCategory),
      mergeMap(action =>
        this.menuCategoryService.menuCategoryControllerRemove(action.id).pipe(
          map(() => MenuActions.removeMenuCategorySuccess({ id: action.id, menuId: action.menuId })),
          catchError(() => of(MenuActions.removeMenuCategoryFailure())),
        ),
      ),
    ),
  );

  public removeMenuCategoryProduct$: Observable<any> = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.removeMenuCategoryProduct),
      mergeMap(action =>
        this.menuCategoryProductService.menuCategoryProductControllerRemove(action.menuCategoryProductId).pipe(
          map(() => MenuActions.removeMenuCategoryProductSuccess({ ...action })),
          catchError(() => of(MenuActions.removeMenuCategoryProductFailure())),
        ),
      ),
    ),
  );

  public getMenuTimetables$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.getMenuTimetables),
      mergeMap(({ establishmentId, menuId }) =>
        this.establishmentService.establishmentV2ControllerGetMenuTimetables(establishmentId, menuId).pipe(
          map(timetables => MenuActions.getMenuTimetablesSuccess({ menuId, timetables })),
          catchError(() => of(MenuActions.getMenuTimetablesFailure())),
        ),
      ),
    ),
  );

  public createMenuTimetables$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.createMenuTimetables),
      mergeMap(({ establishmentId, menuId, timetables }) =>
        this.establishmentService.establishmentV2ControllerCreateOrUpdateMenuTimetables(timetables, establishmentId, menuId).pipe(
          map(newTimetables => MenuActions.createMenuTimetablesSuccess({ menuId, timetables: newTimetables })),
          catchError(() => of(MenuActions.createMenuTimetablesFailure())),
        ),
      ),
    ),
  );

  public removeMenuTimetables$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.removeMenuTimetables),
      mergeMap(({ establishmentId, menuId }) =>
        this.establishmentService.establishmentV2ControllerDeleteMenuTimetables(establishmentId, menuId).pipe(
          map(() => MenuActions.removeMenuTimetablesSuccess({ menuId })),
          catchError(() => of(MenuActions.removeMenuTimetablesFailure())),
        ),
      ),
    ),
  );

  public closeModal$: Observable<unknown> = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuActions.createMenuTimetablesSuccess, MenuActions.removeMenuTimetablesSuccess),
      tap(async () => {
        const modal = await this.modalController.getTop();
        modal?.dismiss();
      }),
    ), { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<RootState>,
    private readonly toast: ToastService,
    private readonly i18n: TranslateService,
    private readonly menuService: MenusService,
    private readonly menuCategoryService: MenuCategoriesService,
    private readonly menuCategoryProductService: MenuCategoryProductsService,
    private readonly establishmentService: EstablishmentsService,
    private readonly translationService: TranslationsService,
    private readonly navController: NavController,
    private readonly toastController: ToastController,
    private readonly modalController: ModalController,
  ) {}

  private async showToast(message: string): Promise<void> {
    const toast = await this.toastController.create({
      message,
      color: "danger",
    });
    await toast.present();
  }
}
