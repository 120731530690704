import { Injectable } from "@angular/core";
import { ToastController } from "@ionic/angular";

export type ToastColor = "primary" | "secondary" | "tertiary" | "success" | "warning" | "danger" | "light" | "medium" | "dark";

@Injectable({ providedIn: "root" })
export class ToastService {
  constructor(private readonly toastController: ToastController) {}

  public async presentToast(message: string, color?: ToastColor, duration: number = 5000): Promise<void> {
    const toast = await this.toastController.create({ message, duration, color, position: "bottom" });
    return toast.present();
  }
}
