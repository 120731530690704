import { registerLocaleData } from "@angular/common";
import { Injectable } from "@angular/core";
import { createEffect, ofType } from "@ngrx/effects";
import { Actions } from "@ngrx/effects";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { Establishment } from "src/app/services/api";
import { TranslateService } from "@ngx-translate/core";
import * as AppActions from "./actions";
import catalan from "@angular/common/locales/ca";
import english from "@angular/common/locales/en";
import french from "@angular/common/locales/fr";
import german from "@angular/common/locales/de";
import italian from "@angular/common/locales/it";
import portuguese from "@angular/common/locales/pt";
import spanish from "@angular/common/locales/es";

@Injectable()
export class AppEffects {
  public changeLanguage$: Observable<any> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AppActions.changeLanguage),
        tap(({ language }) => {
          this.translateService.use(language.toLowerCase());
          switch (language) {
            case Establishment.EnabledLanguagesEnum.Catalan:
              registerLocaleData(catalan);
              break;
            case Establishment.EnabledLanguagesEnum.English:
              registerLocaleData(english);
              break;
            case Establishment.EnabledLanguagesEnum.French:
              registerLocaleData(french);
              break;
            case Establishment.EnabledLanguagesEnum.German:
              registerLocaleData(german);
              break;
            case Establishment.EnabledLanguagesEnum.Italian:
              registerLocaleData(italian);
              break;
            case Establishment.EnabledLanguagesEnum.Portuguese:
              registerLocaleData(portuguese);
              break;
            case Establishment.EnabledLanguagesEnum.Spanish:
              registerLocaleData(spanish);
              break;
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(private actions$: Actions, private translateService: TranslateService) {}
}
