import { createAction, props } from "@ngrx/store";
import { Translation } from "src/app/services/api";

export const createSuccess = createAction("[Translation] Create Success", props<{ translation: Translation }>());

export const createFailure = createAction("[Translation] Create Failure");

export const remove = createAction("[Translation] Remove", props<{ id: number }>());

export const removeSuccess = createAction("[Translation] Remove Success", props<{ id: number }>());

export const removeFailure = createAction("[Translation] Remove Failure");

export const loadByMenu = createAction("[Translation] Load By Menu", props<{ menuId: number }>());

export const loadByMenuSuccess = createAction("[Translation] Load By Menu Success", props<{ translations: Translation[] }>());

export const loadByMenuFailure = createAction("[Translation] Load By Menu Failure");
