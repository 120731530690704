import { DEFAULT_LANGUAGE } from "src/app/constants/app";
import { Establishment } from "src/app/services/api";

export interface AppState {
  language: Establishment.EnabledLanguagesEnum;
}

export const initialState: AppState = {
  language: DEFAULT_LANGUAGE,
};
