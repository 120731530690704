import { User } from "src/app/services/api";
import { RootState } from "..";

export const selectLoading = (state: RootState): boolean => state.user.loading;

export const selectUser = (state: RootState): User => state.user.user;

export const selectActualUserSubscription = (state: RootState): User.SubscriptionEnum => state.user.user?.subscription;

export const selectAccessToken = (state: RootState): string => state.user.accessToken;

export const selectRefreshToken = (state: RootState): string => state.user.refreshToken;
