/**
 * Oh My Menu
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.23
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Product } from './product';


export interface Price { 
    id?: number;
    product?: Product;
    serving?: Price.ServingEnum;
    price: number;
}
export namespace Price {
    export type ServingEnum = 'DISH' | 'TAPA' | 'PORTION' | 'HALFPORTION' | 'BOTTLE' | 'CUP' | 'UNIT' | 'JAR' | 'PINT' | 'CAN' | 'CAÑA';
    export const ServingEnum = {
        Dish: 'DISH' as ServingEnum,
        Tapa: 'TAPA' as ServingEnum,
        Portion: 'PORTION' as ServingEnum,
        Halfportion: 'HALFPORTION' as ServingEnum,
        Bottle: 'BOTTLE' as ServingEnum,
        Cup: 'CUP' as ServingEnum,
        Unit: 'UNIT' as ServingEnum,
        Jar: 'JAR' as ServingEnum,
        Pint: 'PINT' as ServingEnum,
        Can: 'CAN' as ServingEnum,
        Caa: 'CAÑA' as ServingEnum
    };
}


