import { Translation } from "src/app/services/api";

export interface Translations {
  [id: number]: Translation;
}

export interface TranslationState {
  translations: Translations;
  loading: boolean;
}

export const initialState: TranslationState = {
  translations: {},
  loading: false,
};
