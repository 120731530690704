/**
 * Oh My Menu
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.23
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Timetable } from './timetable';
import { Translation } from './translation';
import { MenuCategory } from './menu-category';
import { Establishment } from './establishment';


export interface Menu { 
    id?: number;
    establishmentId: number;
    establishment: Establishment;
    menuCategories?: Array<MenuCategory>;
    translations?: Array<Translation>;
    name: string;
    description?: string;
    active: boolean;
    type: Menu.TypeEnum;
    image?: string;
    price?: number;
    timetables: Array<Timetable>;
    createdAt?: string;
    updatedAt?: string;
}
export namespace Menu {
    export type TypeEnum = 'MENU' | 'DAILYMENU';
    export const TypeEnum = {
        Menu: 'MENU' as TypeEnum,
        Dailymenu: 'DAILYMENU' as TypeEnum
    };
}


