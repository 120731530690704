import { RootState } from "src/app/store";
import { Store } from "@ngrx/store";
import * as AppActions from "src/app/store/app/actions";
import * as UserActions from "src/app/store/user/actions";
import * as FromApp from "src/app/store/app/selectors";
import * as FromUser from "src/app/store/user/selectors";
import { first, withLatestFrom } from "rxjs/operators";
import { SentryService } from "../sentry/sentry.service";
import { MAP_LANGUAGE_ISO_TO_ENUM } from "src/app/constants/app";

export const refreshAccessTokenProviderFactory = (store: Store<RootState>, sentryService: SentryService): () => Promise<void> =>
  async () => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      if (queryParams?.get("overwrite_language")) {
        const language = MAP_LANGUAGE_ISO_TO_ENUM[queryParams?.get("overwrite_language")];
        if (language) {
          store.dispatch(AppActions.changeLanguage({ language }));
        }
      }

      store
        .select(FromUser.selectRefreshToken)
        .pipe(withLatestFrom(store.select(FromApp.selectLanguage)), first())
        .subscribe(([refreshToken, language]) => {
          store.dispatch(AppActions.changeLanguage({ language }));
          if (refreshToken) {
            store.dispatch(UserActions.refreshToken({ refreshToken }));
            store.dispatch(UserActions.load({}));
          }
        });
    } catch (e) {
      console.error(e);
      sentryService.handleError(e);
    }
  };
