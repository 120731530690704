import { createReducer, on } from "@ngrx/store";
import { initialState } from "./state";
import * as TranslationActions from "./actions";

export const translationReducer = createReducer(
  initialState,

  on(TranslationActions.loadByMenu, TranslationActions.remove, state => ({ ...state, loading: true })),

  on(
    TranslationActions.createFailure,
    TranslationActions.loadByMenuFailure,
    TranslationActions.removeFailure,
    state => ({ ...state, loading: false }),
  ),

  on(TranslationActions.createSuccess, (state, { translation }) => ({
    ...state,
    translations: {
      ...state.translations,
      [translation.id]: { ...state.translations[translation.id], ...translation },
    },
    loading: false,
  })),

  on(TranslationActions.loadByMenuSuccess, (state, { translations }) => {
    const entities = {};
    translations.forEach(translation => (entities[translation.id] = translation));

    return {
      ...state,
      translations: { ...state.translations, ...entities },
      loading: false,
    };
  }),

  on(TranslationActions.removeSuccess, (state, { id }) => {
    const entities = {};
    Object.values(state.translations).forEach(translation => {
      if (translation.id !== id) {
        entities[translation.id] = translation;
      }
    });

    return {
      ...state,
      translations: entities,
      loading: false,
    };
  }),
);
