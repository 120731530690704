/**
 * Oh My Menu
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.23
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface User { 
    id?: number;
    readonly customerId?: string;
    readonly googleId?: string;
    email: string;
    name?: string;
    subscription?: User.SubscriptionEnum;
    subscriptionValidUntil?: string;
    password: string;
    emailVerified?: boolean;
    language: User.LanguageEnum;
    createdAt?: string;
    updatedAt?: string;
    deletedAt?: string;
}
export namespace User {
    export type SubscriptionEnum = 'REGULAR' | 'LITE' | 'PREMIUM';
    export const SubscriptionEnum = {
        Regular: 'REGULAR' as SubscriptionEnum,
        Lite: 'LITE' as SubscriptionEnum,
        Premium: 'PREMIUM' as SubscriptionEnum
    };
    export type LanguageEnum = 'SPANISH' | 'ENGLISH' | 'CATALAN' | 'GERMAN' | 'FRENCH' | 'ITALIAN' | 'PORTUGUESE';
    export const LanguageEnum = {
        Spanish: 'SPANISH' as LanguageEnum,
        English: 'ENGLISH' as LanguageEnum,
        Catalan: 'CATALAN' as LanguageEnum,
        German: 'GERMAN' as LanguageEnum,
        French: 'FRENCH' as LanguageEnum,
        Italian: 'ITALIAN' as LanguageEnum,
        Portuguese: 'PORTUGUESE' as LanguageEnum
    };
}


