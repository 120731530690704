import { Injectable } from "@angular/core";
import { LoadingController } from "@ionic/angular";

@Injectable({ providedIn: "root" })
export class LoadingService {
  private loading: HTMLIonLoadingElement;

  constructor(private loadingController: LoadingController) {
    this.createLoading();
  }

  public async present(): Promise<void> {
    if (!this.loading) {
      await this.createLoading();
    }
    await this.loading.present();
  }

  public dismiss(): void {
    this.loading?.dismiss();
    this.loading = null;
  }

  private async createLoading(): Promise<void> {
    this.loading = await this.loadingController.create();
  }
}
